const LinkedIn = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.25 9.876C10.2497 10.4064 10.0388 10.915 9.66351 11.2899C9.28825 11.6648 8.77943 11.8753 8.249 11.875C7.71857 11.8747 7.20996 11.6638 6.83508 11.2885C6.46019 10.9132 6.24974 10.4044 6.25 9.874C6.25027 9.34357 6.46123 8.83496 6.83649 8.46008C7.21175 8.08519 7.72057 7.87474 8.251 7.875C8.78143 7.87527 9.29004 8.08623 9.66492 8.46149C10.0398 8.83675 10.2503 9.34557 10.25 9.876ZM10.31 13.356H6.31V25.876H10.31V13.356ZM16.63 13.356H12.65V25.876H16.59V19.306C16.59 15.646 21.36 15.306 21.36 19.306V25.876H25.31V17.946C25.31 11.776 18.25 12.006 16.59 15.036L16.63 13.356Z"
      fill="currentColor"
    />
  </svg>
)

export default LinkedIn
