'use client'

import { useEffect, useState, useMemo } from 'react'
import NextLink from 'next/link'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/joy/styles'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Button from '@audioeye-marketing/ui/src/components/Button'
import Divider from '@audioeye-marketing/ui/src/components/Divider'
import List from '@audioeye-marketing/ui/src/components/List'
import ListItemButton from '@audioeye-marketing/ui/src/components/ListItemButton'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import Modal from '@/components/storyblok/items/Modal'
import generateIdFromText from '@/lib/generate-id-from-text'

const TableOfContents = ({ pageData, backButton, sx }) => {
  const [activeHeading, setActiveHeading] = useState(null)
  const theme = useTheme()
  const lowerThanLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const headings = useMemo(() => {
    const extractedHeadings = []

    pageData?.body?.forEach((currentSection) => {
      if (currentSection?.text_content?.[0]?.heading) {
        extractedHeadings.push(currentSection?.text_content?.[0]?.heading)
      }

      if (currentSection?.component === 'Rich Text Content') {
        currentSection?.rich_text?.[0]?.rich_text?.content.forEach((content) => {
          if (content.type === 'heading' && content.attrs.level === 2) {
            extractedHeadings.push(content.content[0].text)
          }
        })
      }
    })

    return extractedHeadings
  }, [pageData])

  useEffect(() => {
    const headingElements = headings.map((heading) => {
      return document.getElementById(generateIdFromText(heading))
    })

    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting)
        if (visibleEntry) {
          setActiveHeading(visibleEntry.target.id)
        }
      },
      { rootMargin: '10px 0px 10px 0px', threshold: 0 }
    )

    headingElements.forEach((el) => {
      if (el) observer.observe(el)
    })

    return () => {
      headingElements.forEach((el) => {
        if (el) observer.unobserve(el)
      })
    }
  }, [headings])

  return (
    <Box
      component="section"
      sx={{
        maxWidth: { xs: '100%', lg: '446px' },
        width: '100%',
        pt: { xs: 5, lg: 4 },
        pb: { xs: 0, lg: 4 },
        pl: { xs: 5, lg: 0 },
        pr: { xs: 5, lg: 1 },
        top: 0,
        position: { xs: 'relative', lg: 'sticky' },
        height: { xs: 'auto', lg: '100vh' },
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        ...sx,
      }}
    >
      <Container constraint="sm">
        <Typography level="title-lg" sx={{ pl: 1, mb: 3 }}>
          Table of contents
        </Typography>
        {backButton}
        <Stack component="nav" spacing={2} sx={{ mb: 2 }}>
          <List size="sm">
            {headings.map((heading) => {
              const id = generateIdFromText(heading)
              const isActive = activeHeading === id

              return (
                <ListItemButton
                  key={heading}
                  component={NextLink}
                  size="sm"
                  href={`#${id}`}
                  sx={{
                    mb: 1,
                    transition: {
                      xs: '',
                      lg: 'color 0.3s ease-in-out, opacity 0.3s ease-in-out, border-right 0.3s ease-in-out',
                    },
                    opacity: {
                      xs: '',
                      lg: isActive ? 1 : 0.6,
                    },
                    borderRight: {
                      xs: '',
                      lg: isActive ? `2px solid ${colors.neutral[600]}` : '2px solid transparent',
                    },
                  }}
                >
                  <Typography
                    level={lowerThanLargeScreen ? 'title-sm' : activeHeading === id ? 'title-sm' : 'body-sm'}
                    sx={{
                      color: { xs: colors.primary[500], lg: 'inherit' },
                      transition: { xs: '', lg: 'color 0.3s ease-in-out' },
                    }}
                  >
                    {heading}
                  </Typography>
                </ListItemButton>
              )
            })}
          </List>
        </Stack>
        <Divider />
        <Stack sx={{ display: { xs: 'none', lg: 'block' }, pl: 1, mt: 2, mb: 2, maxWidth: '250px' }} spacing={2}>
          <Typography level="title-md">CTA message want to get started in Accessibility?</Typography>
          <Modal
            blok={{
              _uid: '0a002bbc-465a-42dd-814f-7c6ed1d617f1',
              content: [
                {
                  component: 'Form',
                  hubspot_form: {
                    formName: 'Free Consultation',
                    formLanguage: 'English',
                    stagingHubspotFormIdNoConsent: 'f18a5d8a-4364-417f-ae23-7bd8323d2e3f',
                    productionHubspotFormIdNoConsent: '496fc7a9-716e-4248-8e7b-d4e826a2609f',
                    stagingHubspotFormIdConsentRequired: '6a2c0140-6fdc-4501-9290-5ccecaa30b81',
                    productionHubspotFormIdConsentRequired: '051afc10-1a39-40ac-85b0-00fdd04f4216',
                  },
                },
              ],
              component: 'Modal',
            }}
            trigger={<Button size="sm">Schedule Demo</Button>}
          />
        </Stack>
      </Container>
    </Box>
  )
}

export default TableOfContents
