'use client'

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import Facebook from '@/assets/social-logos/Facebook'
import LinkedIn from '@/assets/social-logos/LinkedIn'
import X from '@/assets/social-logos/X'
import ScannerForm from '@/components/storyblok/items/ScannerForm'
import translateSectionStyles from '@/lib/translate-section-styles'

const PostFooter = () => {
  const sectionStyles = translateSectionStyles({ background_color: { value: colors.neutral[50] } })

  return (
    <>
      <Section {...sectionStyles}>
        <Container constraint="lg">
          <Grid direction="row" container spacing={{ xs: 5, md: 10 }}>
            <Grid xs={12} md={6}>
              <Typography level="h2">Ready to test your website for accessibility?</Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Stack sx={{ height: '100%' }} alignItems={{ xs: 'flex-start', sm: 'center' }} justifyContent="center">
                <ScannerForm blok={{ card: true }} />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Box sx={{ backgroundColor: colors.neutral[50], padding: '20px 0', margin: '0 auto', width: '100%' }}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Typography level="title-sm" sx={{ ml: 1 }}>
            Share Article
          </Typography>
          <FacebookShareButton url={window?.location}>
            <Facebook />
          </FacebookShareButton>
          <LinkedinShareButton url={window?.location}>
            <LinkedIn />
          </LinkedinShareButton>
          <TwitterShareButton url={window?.location}>
            <X />
          </TwitterShareButton>
        </Stack>
      </Box>
    </>
  )
}

export default PostFooter
