'use client'

import React from 'react'
import JoySwitch from '@mui/joy/Switch'
import { useFormContext } from '../Form/FormProvider'
import trackEvent from '../../lib/track-event'

const Switch = React.forwardRef(({ onBlur, onFocus, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <JoySwitch
      ref={ref}
      onBlur={(e) => {
        onBlur && onBlur(e)

        trackEvent('Form Value Changed', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'switch',
          'New Value': e.target.checked,
        })
      }}
      onFocus={(e) => {
        onFocus && onFocus(e)

        trackEvent('Form Value Focused', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'switch',
          'Current Value': e.target.checked,
        })
      }}
      {...rest}
    />
  )
})

Switch.displayName = 'Switch'

export default Switch
