'use client'

import JoyMenuButton from '@mui/joy/MenuButton'
import trackEvent from '../../lib/track-event'

// eslint-disable-next-line no-unused-vars -- Don't need this for MenuButton, but its passed because its the same config as Button/others
const MenuButton = ({ children, triggersWhat, ...rest }) => {
  return (
    <JoyMenuButton
      {...rest}
      onClick={() => {
        Boolean(rest.onClick) && rest.onClick()

        trackEvent('Menu Opened', {
          'Menu Button Text': children,
        })
      }}
    >
      {children}
    </JoyMenuButton>
  )
}

export default MenuButton
