'use client'

import React from 'react'
import JoySlider from '@mui/joy/Slider'
import { useFormContext } from '../Form/FormProvider'
import trackEvent from '../../lib/track-event'

const Slider = React.forwardRef(({ onBlur, onFocus, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <JoySlider
      ref={ref}
      onBlur={(e) => {
        onBlur && onBlur(e)

        trackEvent('Form Value Changed', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'slider',
          'New Value': e.target.value,
        })
      }}
      onFocus={(e) => {
        onFocus && onFocus(e)

        trackEvent('Form Value Focused', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'slider',
          'Current Value': e.target.value,
        })
      }}
      {...rest}
    />
  )
})

Slider.displayName = 'Slider'

export default Slider
