const Facebook = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.25 18.375H20.75L21.75 14.375H18.25V12.375C18.25 11.345 18.25 10.375 20.25 10.375H21.75V7.015C21.424 6.972 20.193 6.875 18.893 6.875C16.178 6.875 14.25 8.532 14.25 11.575V14.375H11.25V18.375H14.25V26.875H18.25V18.375Z"
      fill="currentColor"
    />
  </svg>
)

export default Facebook
