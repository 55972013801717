'use client'

import React from 'react'
import NextLink from 'next/link'
import JoyIconButton from '@mui/joy/IconButton'
import trackEvent from '../../lib/track-event'

const IconButton = React.forwardRef(({ children, triggersWhat, ...rest }, ref) => {
  const iconButtonProps = rest.href ? { component: NextLink } : {}

  return (
    <JoyIconButton
      {...rest}
      {...iconButtonProps}
      ref={ref}
      onClick={(event) => {
        Boolean(rest.onClick) && rest.onClick()

        const isInHeader = event.currentTarget.closest('header') !== null
        const isInFooter = event.currentTarget.closest('footer') !== null

        trackEvent('Icon Button Clicked', {
          Trigger: rest.href ? 'Link' : triggersWhat,
          Destination: rest.href,
          Target: rest.target,
          Location: isInHeader ? 'Header' : isInFooter ? 'Footer' : 'Body',
        })
      }}
    >
      {children}
    </JoyIconButton>
  )
})

IconButton.displayName = 'IconButton'

export default IconButton
