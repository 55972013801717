import { FormProvider as UIFormProvider } from './FormProvider'

const Form = ({ name, children, ...rest }) => {
  return (
    <UIFormProvider formName={name}>
      <form name={name} {...rest}>
        {children}
      </form>
    </UIFormProvider>
  )
}

export default Form
