'use client'

import { useEffect } from 'react'
import 'highlight.js/styles/a11y-dark.css'
import hljs from 'highlight.js'
import { storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'

const CodeBlock = ({ blok }) => {
  useEffect(() => {
    hljs.highlightAll()
  }, [])

  return (
    <Box>
      <pre {...storyblokEditable(blok)}>
        <code className={blok?.code?.language?.toLowerCase()}>{blok?.code?.code}</code>
      </pre>
    </Box>
  )
}

export default CodeBlock
