'use client'

import JoyMenuItem from '@mui/joy/MenuItem'
import trackEvent from '../../lib/track-event'
import getTextFromChildren from '../../lib/get-text-from-children'

const MenuItem = ({ children, triggersWhat, ...rest }) => {
  return (
    <JoyMenuItem
      {...rest}
      onClick={(event) => {
        Boolean(rest.onClick) && rest.onClick()

        const isInHeader = event.currentTarget.closest('header') !== null
        const isInFooter = event.currentTarget.closest('footer') !== null

        trackEvent('Menu Item Clicked', {
          'Menu Item Text': getTextFromChildren(children),
          Trigger: rest.href ? 'Link' : triggersWhat,
          Destination: rest.href,
          Target: rest.target,
          Location: isInHeader ? 'Header' : isInFooter ? 'Footer' : 'Body',
        })
      }}
    >
      {children}
    </JoyMenuItem>
  )
}

export default MenuItem
