/**
 * @param {string} text - the text you want to use as the id attribute or href value
 * @return {string} - string for the id or href attribute
 */
const generateIdFromText = (text) => {
  return text
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading/trailing spaces
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Ensure no double hyphens
}

export default generateIdFromText
