const X = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9047 15.3446L25.3514 6.875H23.5874L17.1187 14.2275L11.956 6.875H6L13.8087 17.9944L6 26.875H7.76401L14.5907 19.1088L20.044 26.875H26.0001M8.40067 8.17658H11.1107L23.5861 25.6374H20.8754"
      fill="currentColor"
    />
  </svg>
)

export default X
