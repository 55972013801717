export const key = '_audioeye_data'

/**
 * @param {object} values - object you want to persist in local storage
 */
export function persistData(values) {
  if (typeof window === 'undefined' || typeof values === 'undefined' || values === 'undefined') {
    return
  }
  const data = JSON.stringify(values)
  localStorage.setItem(key, data)
}

/**
 * @return {object} - object that was persisted in local storage
 */
export function getPersistedData() {
  if (typeof window === 'undefined' || localStorage.getItem(key) === null) {
    return {}
  }
  const value = localStorage.getItem(key)
  if (value === 'undefined') {
    return {}
  }
  return JSON.parse(localStorage.getItem(key) || '{}')
}

/**
 * @param {string} timestamp - string of the timestamp when the data was persisted
 * @return {boolean} - returns a boolean if the data is expired
 */
export function checkExpiredData(timestamp) {
  const dayMs = 1000 * 60 * 60 * 24
  const dayAgo = Date.now() - dayMs
  return timestamp > dayAgo
}
